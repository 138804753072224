<template>
  <div>
    <v-container grid-list-lg class="carder height">
      <v-row>
        <div class="flex justify-center">
          <v-text-field
            outlined
            dense
            rounded
            class="ma-3"
            color="blue"
            label="Search"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            @change="findInvoice"
          ></v-text-field>
        </div>
      </v-row>
      <v-row>
        <div v-if="invoice == null" class="flex justify-center flex-column">
          <v-icon class="grey--text text--disabled" size="200">
            mdi-clipboard-text-search
          </v-icon>
          <span
            class="
              text-h3
              grey--text
              text--disabled
              font-weight-bold
              my-3
              nunito
              text-center
            "
          >
            Begin search by typing in the search bar
          </span>
        </div>
        <div v-else class="flex flex-column">
          {{ getOut("invoice", invoice) }}
          <div class="ml-5">
            <span class="text-right text-captalized">Name :</span>
            <span class="text-left">
              {{ invoice.guest.name }}
            </span>
          </div>
          <div class="ml-5">
            <span class="text-right">Phone number :</span>

            <span class="text-left">
              {{ invoice.guest.phone }}
            </span>
          </div>
          <div class="ml-5">
            <span class="text-right">Address :</span>

            <span class="text-left">
              {{ invoice.guest.address }}
            </span>
          </div>
          <div class="ml-5">
            <span class="text-right">Address 2 :</span>

            <span class="text-left">
              {{ invoice.guest.address2 }}
            </span>
          </div>
          <div class="ml-5">
            <span class="text-right">Postcode :</span>

            <span class="text-left">
              {{ invoice.guest.postcode }}
            </span>
          </div>
          <div class="ml-5">
            <span class="text-right">Created at :</span>
            <span class="text-left">
              {{ returnDate(invoice.createdAt) }}
            </span>
          </div>
          <div class="flex ml-5 mt-2">
            <div class="flex flex-column">
              <div class="">
                Merchant :
                <span class="blue-grey--text font-weight-bold">{{
                  invoice.outlet_name
                }}</span>
              </div>
            </div>
          </div>
          <v-simple-table class="table ma-3" height="40vh">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Product Name</th>
                  <th class="text-left">Price</th>
                  <th class="text-left">Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in invoice.cart" :key="key">
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.unit_price }}
                  </td>
                  <td>
                    {{ item.quantity }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="flex flex-column ml-5" v-if="invoice.voucher">
            <span class="">
              <b>voucher code : </b>
              <b class="orange--text">{{ invoice.voucher.code }}</b>
            </span>
            <span class="">
              <b>discount : RM</b>
              <b class="orange--text">{{ invoice.voucher.discount }}</b>
            </span>
          </div>
          <div class="flex flex-column ml-5" v-if="invoice.delivery">
            <span class="">
              <b>delivery : {{ invoice.delivery.provider }}</b>
              <b class="orange--text font-weight-bold">
                ({{ invoice.delivery.tracking_no }})</b
              >
            </span>
          </div>
          <div class="flex flex-column ml-5" v-if="invoice.delivery_fees">
            <span class="">
              <b>delivery fees : RM </b>
              <span class="orange--text font-weight-bold">{{
                invoice.delivery_fees
              }}</span>
            </span>
          </div>
          <div class="flex ml-5">
            <span class="text-h5"
              ><b>Total : RM </b
              ><b class="orange--text">{{ returnTotal(invoice) }}</b></span
            >
          </div>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
export default {
  name: "Tracker",

  data() {
    return {
      search: "",
      invoice: null,
    };
  },

  computed: {
    ...mapGetters({
      desserts: "getUserData",
    }),
  },

  methods: {
    findInvoice(value) {
      this.$store.dispatch("startLoad");
      this.$store.dispatch("fetchInvoice", value).then((d) => {
        // console.log(d);
        this.invoice = d.data;
        this.$store.dispatch("endLoad");
      });
    },

    getOut(str, i) {
      console.log(str, i);
    },

    returnDate(i) {
      return dayjs(i).format("DD/MM/YY h:mmA");
    },

    returnTotal(invoice) {
      let cart = this.cart_total(invoice.cart);
      if (invoice.voucher) {
        cart = cart - parseFloat(invoice.voucher.discount);
      }
      if (invoice.delivery_fees) {
        cart = cart + parseFloat(invoice.delivery_fees);
        // console.log("delivery fees", cart);
      }
      return parseFloat(cart).toFixed(2);
    },

    cart_total(cart) {
      return cart.reduce((sum, item) => {
        var mods = item.modifiers.reduce((sum, mod) => {
          var amt = parseFloat(mod.amount);
          var actual = mod.mode == 1 ? amt : mod.mode == 2 ? -amt : 0;
          return parseFloat(sum) + actual;
        }, 0);
        return (
          parseFloat(sum) +
          (parseFloat(item.unit_price) + parseFloat(mods)) * item.quantity
        );
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.height {
  height: 95vh;
  overflow-y: auto;
}
.table {
  overflow-y: auto;
  height: 62%;
}
</style>
